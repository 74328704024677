import { routeConfiguration } from 'index';
import React, { useState, useRef } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { createResourceLocatorString } from 'util/routes';
import css from './FiltersSection.module.css';
import AutocompleteCss from './LocationAutocompleteInput.css';
import AutocompleteSingleSelectFilter from './components/AutocompleteSingleSelectFilter/AutocompleteSingleSelectFilter';
import config from 'config';
import ChipsFilter from './components/ChipsFilter/ChipsFilter';
import KeywordFilter from './components/KeywordsFilter/KeywordsFilter';
import PriceFilter from './components/PriceFilter/PriceFilter';
import PriceFilterOnPopup from './components/PriceFilter/PriceFilterOnPopup';
import { Box } from '@material-ui/core';
import MultiSelectionFilter from './components/MultiSelectionFilter/MultiSelectionFilter';
import MultiSelectionFilterOnPopup from './components/MultiSelectionFilter/MultiSelectionFilterOnPopup';
import Collapsible from 'react-collapsible';
import { Close as CloseIcon, Tune as TuneIcon} from '@mui/icons-material';
import styled from 'styled-components';
import { Badge, Modal } from '@mui/material';
import { convertMultipleSelectQueryStringToArray } from './utils';
import { PrimaryButton, SecondaryButton, LocationAutocompleteInput, Form } from 'components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 16,
  py: 3,
  px: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 6,
    top: 6,
    border: `1px solid #fff`,
    padding: '0 4px',
  },
}));

const identity = v => v;

const FiltersSection = props => {
  const { currentQueryParams, history } = props;

  // badge content calculation
  const priceLength = currentQueryParams?.price ? 1 : 0;
  const specialisationValue = currentQueryParams && convertMultipleSelectQueryStringToArray(currentQueryParams?.pub_suggestion_category);
  const specialisationLength = specialisationValue ? specialisationValue.length : 0;
  const languageValue = currentQueryParams && convertMultipleSelectQueryStringToArray(currentQueryParams?.pub_search_languages);
  const languageLength = languageValue ? languageValue.length : 0;
  const interestsValue = currentQueryParams && convertMultipleSelectQueryStringToArray(currentQueryParams?.pub_interests);
  const interestsLength = interestsValue ? interestsValue.length : 0;
  const badgeContent = priceLength + specialisationLength + languageLength + interestsLength;

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchLocation, setSearchLocation] = useState({
    predictions: [],
    search: currentQueryParams.address,
    selectedPlace: {
      address: currentQueryParams.address
    }
  });

  const locationSearchInput = useRef(null);

  const handleFiltersChange = newValues => {
    const newParams = {
      ...currentQueryParams,
      ...newValues,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, newParams));
  };

  const handleResetFilters = () => {
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { pub_listing_type: 'listing' }
      )
    );
  };

  const closeModal = () => {
    setFilterModalOpen(false);
  }

  const onChangeLocation = location => {
    if (location.selectedPlace) {
      setSearchLocation(location);
      const { origin = null, bounds = null, address = null } = location.selectedPlace;
      const newValue = {
        address,
        bounds,
        origin
      };
      handleFiltersChange(newValue);
      if (locationSearchInput.current) {
        locationSearchInput.current.blur();
      }
    }
  }

  const interestsOptions = config.custom.interestsOptions || [];
  const languagesOptions = config.custom.languageFilterConfig;
  const suggestionOptions = config.custom.suggestionCategoryOptions;

  return (
    <div className={css.filtersSectionContainer}>
      <div className={css.subContainer}>
        <div className={css.wrapper}>
          <div className={css.filtersBarWrapper}>
            <div className={css.filtersBar}>
              <div className={css.filtersWrapper}>
                {/* Location */}
                <div className={css.filterWrapper}>
                  <FinalForm
                    onSubmit={() => {
                      console.log("submitted");
                    }}
                    render={formRenderProps => {
                      const preventFormSubmit = e => e.preventDefault();
                      const { desktopInputRoot, isMobile } = formRenderProps;
                      const desktopInputRootClass = desktopInputRoot || AutocompleteCss.desktopInputRoot;

                      return (
                        <Form onSubmit={preventFormSubmit} className={css.formWrapper} autoComplete="off">
                          <div className={css.searchForSearchPage}>
                            <div className={css.countyOrCitySearchQuery}>
                              <Field
                                name="location"
                                format={identity}
                                render={({ input, meta }) => {
                                  const { onChange, ...restInput } = input;

                                  // See: https://github.com/final-form/react-final-form/issues/159
                                  const searchOnChange = value => {
                                    onChange(value);
                                    onChangeLocation(value);
                                  };

                                  const searchInput = { ...restInput, onChange: searchOnChange };
                                  return (
                                    <LocationAutocompleteInput
                                      className={desktopInputRootClass}
                                      iconClassName={AutocompleteCss.desktopIcon}
                                      inputClassName={
                                        isMobile ? AutocompleteCss.mobileInput : AutocompleteCss.desktopInput
                                      }
                                      predictionsClassName={
                                        isMobile
                                          ? AutocompleteCss.mobilePredictions
                                          : AutocompleteCss.desktopPredictions
                                      }
                                      predictionsAttributionClassName={
                                        isMobile ? AutocompleteCss.mobilePredictionsAttribution : null
                                      }
                                      placeholder={'Continent or Country or City?'}
                                      closeOnBlur={!isMobile}
                                      inputRef={node => {
                                        locationSearchInput.current = node;
                                      }}
                                      input={searchInput}
                                      meta={meta}
                                      selectedLocation={searchLocation}
                                      locationTypes={["country", "locality", "place", "district", "region"]}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  />
                </div>
                {/* Price */}
                {/* <div className={css.filterWrapper}>
                  <PriceFilter
                    handleFiltersChange={handleFiltersChange}
                    currentQueryParams={currentQueryParams}
                  />
                </div> */}
                {/* Specialisation */}
                <div className={css.filterWrapper}>
                  <MultiSelectionFilter
                    handleFiltersChange={handleFiltersChange}
                    currentQueryParams={currentQueryParams}
                    searchKey={'pub_suggestion_category'}
                    id={'specializationFilter'}
                    options={suggestionOptions}
                    type={'has_any'}
                    name={'Specialisation'}
                  />
                </div>
                {/* Language */}
                <div className={css.filterWrapper}>
                  <AutocompleteSingleSelectFilter
                    handleFiltersChange={handleFiltersChange}
                    currentQueryParams={currentQueryParams}
                    searchKey={'pub_search_languages'}
                    id={'languagesFilter'}
                    options={languagesOptions}
                    type={'has_any'}
                    placeholder={'Languages'}
                  />
                </div>
                {/* Interests */}
                <div className={css.filterWrapper}>
                  <AutocompleteSingleSelectFilter
                    handleFiltersChange={handleFiltersChange}
                    currentQueryParams={currentQueryParams}
                    searchKey={'pub_interests'}
                    id="interestsFilter"
                    options={interestsOptions}
                    type={'has_any'}
                    placeholder={'Interests'}
                  />
                </div>
              </div>

            </div>
            <div className={css.filterBtnWrapper}>
              <StyledBadge badgeContent={badgeContent} color="success" max={9}>
                <button type='button' className={css.filterBtn} onClick={() => setFilterModalOpen(true)}>
                  <TuneIcon sx={{ height: 31 }} />
                  <span>&nbsp;Filters</span>
                </button>
              </StyledBadge>
              <Modal
                open={filterModalOpen}
                onClose={closeModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Box sx={{ ...style }} className={css.boxClass}>
                  <div className={css.dialogHeading}>
                    <div id="parent-modal-title" className={css.headingTitle}>Filters</div>
                    <span className={css.closeBtnWrapper}>
                      <button className={css.closeBtn} onClick={closeModal}>
                        <CloseIcon />
                      </button>
                    </span>
                  </div>
                  <div className={css.filtersSection}>
                    {/* Chips */}
                    <ChipsFilter
                      handleFiltersChange={handleFiltersChange}
                      currentQueryParams={currentQueryParams}
                      allOptions={[...interestsOptions, ...languagesOptions, ...suggestionOptions]}
                      handleResetFilters={handleResetFilters}
                    />
                    {/* Price */}
                    {/* <Collapsible
                      trigger={
                        <div className={css.itemTitleBar}>
                          <span className={priceLength && css.boldStyle}>Price</span>
                          <span className={css.accordionArrow}></span>
                        </div>
                      }
                      triggerWhenOpen={<div className={css.itemTitleBar}>
                        <span className={priceLength && css.boldStyle}>Price</span>
                        <span className={css.accordionArrowOpen}></span>
                      </div>}
                    >
                      <PriceFilterOnPopup
                        handleFiltersChange={handleFiltersChange}
                        currentQueryParams={currentQueryParams}
                      />
                    </Collapsible> */}
                    {/* Specialisation */}
                    <Collapsible
                      trigger={<div className={css.itemTitleBar}>
                        <span className={specialisationValue && css.boldStyle}>Specialisation</span>
                        <span className={css.accordionArrow}></span>
                      </div>}
                      triggerWhenOpen={<div className={css.itemTitleBar}>
                        <span className={specialisationValue && css.boldStyle}>Specialisation</span>
                        <span className={css.accordionArrowOpen}></span>
                      </div>}
                    >
                      <MultiSelectionFilterOnPopup
                        handleFiltersChange={handleFiltersChange}
                        currentQueryParams={currentQueryParams}
                        searchKey={'pub_suggestion_category'}
                        id={'specializationFilter'}
                        options={suggestionOptions}
                        type={'has_any'}
                        name={'Specialisation'}
                      />
                    </Collapsible>
                    {/* Language */}
                    <Collapsible
                      trigger={<div className={css.itemTitleBar}>
                        <span className={languageValue && css.boldStyle}>Language</span>
                        <span className={css.accordionArrow}></span>
                      </div>}
                      triggerWhenOpen={<div className={css.itemTitleBar}>
                        <span className={languageValue && css.boldStyle}>Language</span>
                        <span className={css.accordionArrowOpen}></span>
                      </div>}
                      contentInnerClassName={css.inputFieldWrapper}
                    >
                      <AutocompleteSingleSelectFilter
                        handleFiltersChange={handleFiltersChange}
                        currentQueryParams={currentQueryParams}
                        searchKey={'pub_search_languages'}
                        id={'languagesFilter'}
                        options={languagesOptions}
                        type={'has_any'}
                        placeholder={'Languages'}
                      />
                    </Collapsible>
                    {/* Interests */}
                    <Collapsible
                      trigger={<div className={css.itemTitleBar}>
                        <span className={interestsValue && css.boldStyle}>Interests</span>
                        <span className={css.accordionArrow}></span>
                      </div>}
                      triggerWhenOpen={<div className={css.itemTitleBar}>
                        <span className={interestsValue && css.boldStyle}>Interests</span>
                        <span className={css.accordionArrowOpen}></span>
                      </div>}
                      contentInnerClassName={css.inputFieldWrapper}
                    >
                      <AutocompleteSingleSelectFilter
                        handleFiltersChange={handleFiltersChange}
                        currentQueryParams={currentQueryParams}
                        searchKey={'pub_interests'}
                        id="interestsFilter"
                        options={interestsOptions}
                        type={'has_any'}
                        placeholder={'Interests'}
                      />
                    </Collapsible>
                    {/* Keywords */}
                    <Collapsible
                      trigger={<div className={css.itemTitleBar}>
                        <span>Keywords</span>
                        <span className={css.accordionArrow}></span>
                      </div>}
                      triggerWhenOpen={<div className={css.itemTitleBar}>
                        <span>Keywords</span>
                        <span className={css.accordionArrowOpen}></span>
                      </div>}
                    >
                      <KeywordFilter
                        handleFiltersChange={handleFiltersChange}
                        currentQueryParams={currentQueryParams}
                      />
                    </Collapsible>
                  </div>
                  <div className={css.footerSection}>
                    <SecondaryButton onClick={handleResetFilters}>Reset all</SecondaryButton>
                    <PrimaryButton onClick={closeModal}>Apply filter</PrimaryButton>
                  </div>
                </Box>
              </Modal>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersSection;
